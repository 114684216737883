import {FAILURE, LOGIN_SUCCESS, LOG_OUT } from '../../actions/Login/index'
const InitialState = {
  Authenticated: false,
  userRole: "admin",
  registered: false,
  token: localStorage.getItem('token')
}
export default function(state = InitialState, action){
  const {type, payload} = action
  switch(type){
      case LOGIN_SUCCESS:
        return { ...state, token: localStorage.getItem('token')}
      case LOG_OUT:
        return  {...state, token: localStorage.removeItem('token')}
      case FAILURE:
          return{
              ...state, 
          }
      default:
          return state
  }
  
}