import React from "react"
import * as Icon from "react-feather"
import {
  ADMIN_LAYOUT, AUDIO_LISTING, CHAPTER_LISTING, EBOOK_LISTING, GRADE_LISTING,
  LANGUAGE_LISTING, NOTES_LISTING, SCHOOL_LISTING, SUBJECT_LISTING, TEST_LISTING,
  USER_LISTING, VIDEO_LISTING, DISTRIBUTOR_LISTING, QUESTIONS_LISTING, NOTIFICATION_LISTING
} from "./RouterConfig";
const role = localStorage.getItem('adminid')

let navigationConfig = [];
if(process.env.REACT_APP_CONTENT_ID == "REVISION"){
  navigationConfig = [
    /*{
      id: "Dashboard",
      title: "Dashboard",
      type: "item",
      icon: <Icon.Home size={20} />,
      permissions: ["admin", "editor"],
      navLink: "/"
    },*/
    {
      id: "User",
      title: "Users",
      type: "item",
      icon: <Icon.Users size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${USER_LISTING}`
    },
    {
      id: "School",
      title: "School",
      type: "item",
      icon: <Icon.Home size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${SCHOOL_LISTING}`
    },
    {
      id: "Grade",
      title: "Grade",
      type: "item",
      icon: <Icon.Layout size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${GRADE_LISTING}`
    },
    {
      id: "Medium",
      title: "Medium",
      type: "item",
      icon: <Icon.Clipboard size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${LANGUAGE_LISTING}`
    },
    {
      id: "subject",
      title: "Subjects",
      type: "item",
      icon: <Icon.FileText size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${SUBJECT_LISTING}`
    },
    {
      id: "chapter",
      title: "Chapters",
      type: "item",
      icon: <Icon.FileText size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${CHAPTER_LISTING}`
    },
    {
      id: "video",
      title: "Video",
      type: "item",
      icon: <Icon.Video size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${VIDEO_LISTING}`
    },
    {
      id: "test",
      title: "Test",
      type: "item",
      icon: <Icon.Bookmark size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${TEST_LISTING}`
    },
    {
      id: "notes",
      title: "Notes",
      type: "item",
      icon: <Icon.Save size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${NOTES_LISTING}`
    },
    {
      id: "audio",
      title: "Audio",
      type: "item",
      icon: <Icon.Speaker size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${AUDIO_LISTING}`
    },
    {
      id: "ebook",
      title: "E-Book",
      type: "item",
      icon: <Icon.Book size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${EBOOK_LISTING}`
    },
    {
      id: "distributor",
      title: "Distributor",
      type: "item",
      icon: <Icon.Briefcase size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${DISTRIBUTOR_LISTING}`
    },
    {
      id: "questions",
      title: "Questions",
      type: "item",
      icon: <Icon.HelpCircle size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${QUESTIONS_LISTING}`
    },
    {
      id: "notifications",
      title: "Notifications",
      type: "item",
      icon: <Icon.Bell size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${NOTIFICATION_LISTING}`
    }
  ];

}else{

  navigationConfig = [
    {
      id: "video",
      title: "Video",
      type: "item",
      icon: <Icon.Video size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${VIDEO_LISTING}`
    },
    {
      id: "test",
      title: "Test",
      type: "item",
      icon: <Icon.Bookmark size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${TEST_LISTING}`
    },
    {
      id: "notes",
      title: "Notes",
      type: "item",
      icon: <Icon.Save size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${NOTES_LISTING}`
    },
    {
      id: "audio",
      title: "Audio",
      type: "item",
      icon: <Icon.Speaker size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${AUDIO_LISTING}`
    },
    {
      id: "ebook",
      title: "E-Book",
      type: "item",
      icon: <Icon.Book size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${EBOOK_LISTING}`
    },
    {
      id: "questions",
      title: "Questions",
      type: "item",
      icon: <Icon.HelpCircle size={20} />,
      badge: "warning",
      navLink: `${ADMIN_LAYOUT}${QUESTIONS_LISTING}`
    }

  ];
}
export default navigationConfig
